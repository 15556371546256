import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'

import BlockBackground from '../BlockBackground/BlockBackground'
import AccordionItem from './AccordionItem'

import styles from './FAQ.module.css'

const QUESTIONS = [
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
  {
    question: 'What is Padel Tenis?',
    answer:
      "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
  },
]

const FAQ = () => {
  const [activeKey, setActiveKey] = useState()

  return (
    <div className={styles.faq}>
      <BlockBackground />
      <div className="container">
        <div className={styles.faqContent}>
          <h3>Frequently Asked Question</h3>
          <Accordion
            className={styles.accordion}
            activeKey={activeKey}
            onSelect={e => setActiveKey(e)}
          >
            {QUESTIONS.map((props, key) => (
              <AccordionItem
                key={key + props.question}
                {...props}
                activeKey={activeKey}
                eventKey={key}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default FAQ
