import React from 'react'

import styles from './TournamentCard.module.css'
import JoinButton from './JoinButton'
import { Image } from 'react-bootstrap'

const CardFooter = ({ gender, userImages, players, maxPlayers }) => {
  return (
    <div className={styles.tournamentCardFooter}>
      <div className={styles.tournamentCardFooterContent}>
        <div className={styles.tournamentCardFooterGender}>
          <p>{gender}</p>
        </div>
        <div className={styles.tournamentCardUserImages}>
          {userImages.map((src, key) => (
            <Image
              className={styles.tournamentCardUser}
              key={'userImage' + key}
              src={src}
            />
          ))}
        </div>
        <p>
          {players}/{maxPlayers}
        </p>
      </div>
      <JoinButton />
    </div>
  )
}

export default CardFooter
