import React from 'react'

import styles from './TournamentCard.module.css'
import { Price } from '../../../../../../utils/icons'

const RatingPrice = ({ rateLimit, price }) => {
  return (
    <div className={styles.ratingPrice}>
      <p>Rating range:</p>
      <p>
        <strong>{rateLimit}</strong>
      </p>
      <div className={styles.ratingPriceSeparator} />
      <Price />
      <p>{price}</p>
      <div className={styles.ratingPriceSeparator} />
    </div>
  )
}

export default RatingPrice
