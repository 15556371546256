import React from 'react'

import { DownloadAppStore, DownloadGooglePlay } from '../../../../utils/icons'

import styles from './DownloadButtons.module.css'

const DownloadButtons = () => {
  return (
    <div className={styles.downloadButtons}>
      <a href="/">
        <DownloadAppStore />
      </a>
      <a href="/">
        <DownloadGooglePlay />
      </a>
    </div>
  )
}

export default DownloadButtons
