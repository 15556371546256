import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const GameRedirect = () => {
  const { gameId } = useParams()

  useEffect(() => {
    if (!gameId) return

    window.location = `app.padel.padel://game/${gameId}`
  }, [gameId])

  useEffect(() => {
    document.title = 'Redirecting to Game'
  }, [])

  return <p>Redirecting to the game....</p>
}

export default GameRedirect
