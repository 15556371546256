import React, { useEffect, useRef } from 'react'

import styles from './NearestEvents.module.css'
import TournamentCard from './components/TournamentCard/TournamentCard'
import Slider from 'react-slick'

const TOURNAMENTS = [
  {
    imageSrc: '/images/tournamentCover.png',
    isRating: true,
    title: 'Game about to start',
    dateTime: '12 June, 12:30AM - 2:30AM',
    locationName: 'We Are Padel Lisbon',
    rateLimit: 1000,
    price: 10,
    pairs: 'Single',
    url: '/',
    gender: 'M',
    maxPlayers: 24,
    players: 8,
    userImages: [
      '/images/userProfile.png',
      '/images/userProfile.png',
      '/images/userProfile.png',
    ],
  },
  {
    imageSrc: '/images/tournamentCover.png',
    title: 'Game about to start',
    dateTime: '12 June, 12:30AM - 2:30AM',
    locationName: 'We Are Padel Lisbon',
    rateLimit: 1000,
    price: 10,
    pairs: 'Single',
    url: '/',
    gender: 'M',
    maxPlayers: 24,
    players: 8,
    userImages: [
      '/images/userProfile.png',
      '/images/userProfile.png',
      '/images/userProfile.png',
    ],
  },
  {
    imageSrc: '/images/tournamentCover.png',
    isRating: true,
    title: 'Game about to start',
    dateTime: '12 June, 12:30AM - 2:30AM',
    locationName: 'We Are Padel Lisbon',
    rateLimit: 1000,
    price: 10,
    pairs: 'Single',
    url: '/',
    gender: 'M',
    maxPlayers: 24,
    players: 8,
    userImages: [
      '/images/userProfile.png',
      '/images/userProfile.png',
      '/images/userProfile.png',
    ],
  },
]

const NearestEvents = () => {
  const ref = useRef(null)

  const sliderSettings = {
    arrows: false,
    centerMode: true,
    variableWidth: true,
    infinite: false,
    centerPadding: '20px',
    ref,
    className: 'nearestEventsSlider',
  }

  useEffect(() => {
    if (ref.current) {
      ref.current?.slickGoTo(1)
    }
  }, [ref])

  return (
    <div className={styles.nearestEvents}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url("/images/NearestEventsMap.png") lightgray 50% / cover no-repeat`,
        }}
        className={styles.nearestEventsBackground}
      />
      <div className={styles.nearestEventsContent}>
        <h3>Here is Some Event in Lisbon</h3>
        <Slider {...sliderSettings}>
          {TOURNAMENTS.map((props, key) => (
            <TournamentCard key={key + props.title} {...props} />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default NearestEvents
