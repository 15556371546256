import React from 'react'
import { Accordion, useAccordionButton } from 'react-bootstrap'
import styles from './FAQ.module.css'
import { Arrow } from '../../../../utils/icons'

const AccordionItem = ({ question, answer, activeKey, eventKey }) => {
  const handleClick = useAccordionButton(eventKey)

  return (
    <div className={styles.accordionCard}>
      <div className={styles.accordionContent}>
        <div className={styles.toggle} onClick={handleClick}>
          <div className={styles.container}>
            <h6>{question}</h6>
          </div>
        </div>
        <Accordion.Collapse
          eventKey={eventKey}
          className={styles.accordionCollapse}
        >
          <p>{answer}</p>
        </Accordion.Collapse>
      </div>
      <Arrow
        customClassName={`${styles.arrow} ${eventKey === activeKey && styles.active}`}
        handleClick={handleClick}
      />
    </div>
  )
}

export default AccordionItem
