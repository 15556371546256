import { LANDING_MENU_NAVIGATION } from '../../../../../../../../utils/constants'
import MenuNavigationItem from './components/MenuNavigationItem'

import styles from './ListMenu.module.css'

const ListMenu = ({ innerClassName = '', onAfterClick = () => {} }) => {
  return (
    <ul className={`${styles.innerMenuList} ${innerClassName}`}>
      {LANDING_MENU_NAVIGATION.map(({ name, link }) => (
        <MenuNavigationItem
          key={link}
          name={name}
          link={link}
          onClickMenuItem={onAfterClick}
        />
      ))}
    </ul>
  )
}

export default ListMenu
