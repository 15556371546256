import React from 'react'
import { Image } from 'react-bootstrap'

import BlockBackground from '../../../BlockBackground/BlockBackground'

import styles from './FeaturesItem.module.css'

const FeaturesItem = ({ isLeft = false, Icon, title, text, imageSrc }) => {
  return (
    <div className={`${styles.featuresItem} ${!isLeft && styles.background}`}>
      {isLeft && <BlockBackground />}
      <div className="container">
        <div
          className={`${styles.featuresItemInner} ${isLeft && styles.reverse}`}
        >
          <div className={styles.featuresItemContent}>
            <div className={styles.featuresItemIconWrap}>
              <Icon />
            </div>
            <h3>{title}</h3>
            <h6>{text}</h6>
          </div>
          <div className={styles.featuresItemImageBlock}>
            <Image src={imageSrc} className={styles.featuresItemImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesItem
