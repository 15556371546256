import React from 'react'

import styles from './GameType.module.css'

const GameType = ({ Icon, title, text, color }) => {
  return (
    <div className={styles.gameType} style={{ backgroundColor: color }}>
      <Icon />
      <h4 className={styles.gameTypeTitle}>{title}</h4>
      <p>{text}</p>
    </div>
  )
}

export default GameType
