import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'

import styles from './Layout.module.css'

const Layout = ({ children }) => {
  return (
    <main className={styles.landing}>
      <Header />
      {children}
      <Footer />
    </main>
  )
}

export default Layout
