import React from 'react'

import styles from './TournamentCard.module.css'

const CardItem = ({ Icon, content }) => {
  return (
    <div className={styles.cardItem}>
      <Icon />
      {content}
    </div>
  )
}

export default CardItem
