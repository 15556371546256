import React from 'react'
import { Image } from 'react-bootstrap'

import DownloadButtons from '../DownloadButtons/DownloadButtons'

import styles from './Main.module.css'

const Main = () => {
  return (
    <div className={styles.main}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url("/images/homeBackground.png") lightgray 50% / cover no-repeat`,
        }}
        className={styles.mainBackground}
      />
      <div className={`container ${styles.mainContent}`}>
        <div className={styles.title}>
          <div className={styles.texts}>
            <h1>Get the app and dive deep to new era of padel</h1>
            <h6>
              Your Padel life will be more organized, your games more balanced,
              and you will never miss a Padel event in your area.
            </h6>
          </div>
          <DownloadButtons />
        </div>
        <div className={styles.phones}>
          <Image className={styles.phonesImage} src="/images/homePhones.png" />
        </div>
      </div>
    </div>
  )
}

export default Main
