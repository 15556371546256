import React from 'react'
import { Button } from 'react-bootstrap'

import styles from './TournamentCard.module.css'

const JoinButton = ({ handleClick = () => {} }) => {
  return (
    <Button onClick={handleClick} className={styles.joinButton}>
      Join Game
    </Button>
  )
}

export default JoinButton
