import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const PlayerRedirect = () => {
  const { playerId } = useParams()

  useEffect(() => {
    console.log(playerId)
    if (!playerId) return

    window.location = `app.padel.padel://player/${playerId}`
  }, [playerId])

  useEffect(() => {
    document.title = 'Redirecting to Player'
  }, [])

  return <p>Redirecting to the player....</p>
}

export default PlayerRedirect
