import ListMenu from '../ListMenu/ListMenu'
import styles from './MobileMenu.module.css'

const MobileMenu = ({ lang, isOpened, onHideMenu }) => {
  const isActiveMenuClass = isOpened ? styles.openMenu : ''
  const showOverlayClass = isOpened ? styles.showOverlay : ''

  return (
    <>
      <div className={`${styles.innerMenu} ${isActiveMenuClass}`}>
        <div className="container">
          <ListMenu
            lang={lang}
            innerClassName={styles.innerMenuLinks}
            onAfterClick={onHideMenu}
          />
        </div>
      </div>
      <div
        onClick={onHideMenu}
        className={`${styles.overlay} ${showOverlayClass}`}
      />
    </>
  )
}

export default MobileMenu
