import React from 'react'

import {
  Calendar,
  GameType,
  Location,
  Rating,
  Share,
  User,
} from '../../../../../../utils/icons'
import RatingPrice from './RatingPrice'
import CardItem from './CardItem'
import CardFooter from './CardFooter'

import styles from './TournamentCard.module.css'

const TournamentCard = ({
  imageSrc,
  isRating = false,
  title,
  dateTime,
  locationName,
  rateLimit,
  price,
  pairs,
  gender,
  players,
  maxPlayers,
  url,
  userImages,
}) => {
  const cardItems = [
    { Icon: GameType, content: <h4>{title}</h4> },
    {
      Icon: Calendar,
      content: <p>{dateTime}</p>,
    },
    {
      Icon: Location,
      content: <p>{locationName}</p>,
    },
    {
      Icon: Rating,
      content: <RatingPrice rateLimit={rateLimit} price={price} />,
    },
    { Icon: User, content: <p>{pairs}</p> },
  ]

  const handleShare = () => {
    const shareData = {
      text: url,
    }
    if (navigator.share && navigator.canShare(shareData)) {
      return navigator.share(shareData)
    }

    navigator.clipboard.writeText(url)
  }

  return (
    <div className={styles.tournamentCard}>
      <div className={styles.tournamentCardInner}>
        <div
          className={styles.tournamentCardImage}
          style={{ backgroundImage: `url("${imageSrc}")` }}
        >
          {isRating && (
            <div className={styles.tournamentCardImageRating}>
              <Rating customClassName={styles.tournamentCardImageRatingIcon} />
            </div>
          )}
        </div>
        <div className={styles.tournamentCardContent}>
          {cardItems.map((props, key) => (
            <CardItem key={key + 'cardItems'} {...props} />
          ))}
        </div>
        <div className={styles.tournamentCardShare} onClick={handleShare}>
          <Share />
        </div>
      </div>
      <CardFooter
        gender={gender}
        players={players}
        maxPlayers={maxPlayers}
        userImages={userImages}
      />
    </div>
  )
}

export default TournamentCard
