import React from 'react'

import styles from './BlockBackground.module.css'
import { Image } from 'react-bootstrap'

const BlockBackground = () => {
  return (
    <div className={styles.blockBackground}>
      <Image
        src="/images/featuresBackground.png"
        className={styles.blockBackgroundImage}
      />
    </div>
  )
}

export default BlockBackground
