import { HashLink as Link } from 'react-router-hash-link'

import styles from '../ListMenu.module.css'

const MenuNavigationItem = ({ link, name, onClickMenuItem }) => {
  return (
    <li className={styles.itemMenuList}>
      <Link onClick={onClickMenuItem} className={styles.itemMenuLink} to={link}>
        {name}
      </Link>
    </li>
  )
}

export default MenuNavigationItem
