import { Home, GameRedirect, TournamentRedirect, PlayerRedirect } from './pages'

export const PUBLIC_ROUTES = [
  {
    path: '/',
    Component: Home,
  },
  { path: '/redirects/game/:gameId', Component: GameRedirect },
  {
    path: '/redirects/tournament/:tournamentId',
    Component: TournamentRedirect,
  },
  { path: '/redirects/player/:playerId', Component: PlayerRedirect },
]
