import React from 'react'

import styles from './FindGame.module.css'
import DownloadButtons from '../DownloadButtons/DownloadButtons'

const FindGame = () => {
  return (
    <div className={styles.inner}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url("/images/findGameBackground.png") lightgray 50% / cover no-repeat`,
        }}
        className={styles.background}
      />
      <div className="container">
        <div className={styles.content}>
          <div className={styles.text}>
            <h1>Become a Member of Padel Community</h1>
            <h6>Just download a free iOS or Android App</h6>
          </div>
          <DownloadButtons />
        </div>
      </div>
    </div>
  )
}

export default FindGame
