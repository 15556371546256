import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const TournamentRedirect = () => {
  const { tournamentId } = useParams()

  useEffect(() => {
    if (!tournamentId) return

    window.location.replace(`app.padel.padel://tournament/${tournamentId}`)
  }, [tournamentId])

  useEffect(() => {
    document.title = 'Redirecting to Tournament'
  }, [])

  return <p>Redirecting to the tournament....</p>
}

export default TournamentRedirect
