export const LANDING_PATHS = {
  HOME: 'home',
  FEATURES: 'features',
  NEAREST_EVENTS: 'nearest-events',
  FAQ: 'faq',
  FIND_GAME: 'find-game',
  GET_THE_APP: 'get-the-app',
}

export const LANDING_MENU_NAVIGATION = [
  {
    name: 'Home',
    link: '#' + LANDING_PATHS.HOME,
  },
  {
    name: 'Features',
    link: '#' + LANDING_PATHS.FEATURES,
  },
  {
    name: 'Nearest Events',
    link: '#' + LANDING_PATHS.NEAREST_EVENTS,
  },
  {
    name: 'FAQ',
    link: '#' + LANDING_PATHS.FAQ,
  },
  {
    name: 'Find Game',
    link: '#' + LANDING_PATHS.FIND_GAME,
  },
  { name: 'Get the app', link: '#' + LANDING_PATHS.GET_THE_APP },
]
