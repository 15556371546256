import React from 'react'

import ChooseVariety from './components/ChooseVariety/ChooseVariety'
import {
  FeaturesMagnifier,
  FeaturesPlus,
  FeaturesTournaments,
} from '../../../../utils/icons'
import FeaturesItem from './components/FeaturesItem/FeaturesItem'

const ITEMS = [
  {
    Icon: FeaturesPlus,
    title: 'Create Your Own Games',
    text: 'Wanna play with friends or organise your own big tournament? It’s super easy now. Just enter parameters for the new event and it’s ready. Wait until people join or invite it by yourself.',
    imageSrc: '/images/featuresCreatePhone.png',
  },
  {
    Icon: FeaturesTournaments,
    title: 'Participate in tournaments',
    text: 'Wanna play with friends or organise your own big tournament? It’s super easy now. Just enter parameters for the new event and it’s ready. Wait until people join or invite it by yourself.',
    imageSrc: '/images/featuresTournamentPhone.png',
    isLeft: true,
  },
  {
    Icon: FeaturesMagnifier,
    title: 'Search for games',
    text: 'Wanna play with friends or organise your own big tournament? It’s super easy now. Just enter parameters for the new event and it’s ready. Wait until people join or invite it by yourself.',
    imageSrc: '/images/featuresSearchPhone.png',
  },
]

const Features = () => {
  return (
    <>
      <ChooseVariety />
      {ITEMS.map(props => (
        <FeaturesItem key={props.title} {...props} />
      ))}
    </>
  )
}

export default Features
