import React from 'react'

import styles from './Footer.module.css'
import {
  Facebook,
  Instagram,
  Logo,
  Telegram,
} from '../../../../../../utils/icons'
import { Link } from 'react-router-dom'

const SOCIAL_LINKS = [
  {
    Logo: Facebook,
    link: '/',
  },
  {
    Logo: Instagram,
    link: '/',
  },
  {
    Logo: Telegram,
    link: '/',
  },
]

const TERMS_PRIVACY = [
  {
    name: 'Terms of Use',
    link: '/',
  },
  {
    name: 'Privacy policy',
    link: '/',
  },
]

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerContent}>
          <div className={styles.logoRights}>
            <Link to="/" className={styles.link}>
              <Logo />
            </Link>
            <small>© FunPadelClub.com. All Rights Reserved 2024</small>
          </div>
          <div className={styles.socialLinks}>
            {SOCIAL_LINKS.map(({ Logo, link }, key) => (
              <a href={link} key={link + key}>
                <Logo />
              </a>
            ))}
          </div>
          <div className={styles.termsPrivacy}>
            {TERMS_PRIVACY.map(({ name, link }) => (
              <Link key={name} to={link}>
                {name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
