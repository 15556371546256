import { Navigate, Route, Routes } from 'react-router-dom'

import { PUBLIC_ROUTES } from './routes'

import './App.css'

const App = () => {
  return (
    <Routes>
      {PUBLIC_ROUTES.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default App
